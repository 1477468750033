var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center pt-50 mb-2"},[_c('h2',{staticClass:"font-weight-bolder m-0"},[_c('b-button',{staticClass:"btn-icon rounded-circle mr-50 bg-white",attrs:{"variant":"white"},on:{"click":_vm.goBack}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}})],1),_vm._v(" "+_vm._s(_vm.$t("CrearContactPerson"))+" ")],1)]),_c('b-card',[_c('validation-observer',{ref:"createUser"},[_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Nombre'),"label-for":"account-name"}},[_c('b-form-input',{attrs:{"name":"name","placeholder":_vm.$t('Nombre')},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Apellidos'),"label-for":"account-surname"}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('Apellidos'),"name":"surname"},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('ClientPhone'),"rules":"max:9|min:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('ClientPhone'),"label-for":"account-phone"}},[_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('vue-country-code',{attrs:{"preferredCountries":['es']},on:{"onSelect":_vm.onSelect}})]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"number","name":"company","placeholder":_vm.$t('Telefono')},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Email'),"rules":"required|email|emailUnique"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Email'),"label-for":"account-e-mail"}},[_c('b-form-input',{attrs:{"type":"email","name":"email","placeholder":_vm.$t('Email')},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[(_vm.currentRole !== 'admin_cliente')?_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Compañía'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Compañía'),"label-for":"account-company"}},[_c('v-select',{attrs:{"label":"name","filterable":false,"searchable":false,"options":_vm.selectCompanies,"placeholder":_vm.$t('Compañía')},model:{value:(_vm.companies),callback:function ($$v) {_vm.companies=$$v},expression:"companies"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2502786322)})],1):_vm._e(),(_vm.currentRole !== 'admin_cliente')?_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Cliente'),"rules":_vm.roles &&
        (_vm.roles.name === 'admin_cliente' ||
          _vm.roles.name === 'operario_cliente' ||
          _vm.roles.name === 'prl_cliente')
        ? 'required'
        : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Cliente'),"label-for":"account-clients"}},[_c('v-select',{attrs:{"label":"name","filterable":false,"searchable":false,"options":_vm.selectClients,"multiple":"","disabled":_vm.disabledClients,"placeholder":_vm.$t('Cliente')},model:{value:(_vm.clients),callback:function ($$v) {_vm.clients=$$v},expression:"clients"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,460737925)})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12 text-right"}},[_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("Guardar"))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }