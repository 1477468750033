<template>
  <div>
    <!-- button -->
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0">
        <b-button @click="goBack" variant="white" class="btn-icon rounded-circle mr-50 bg-white">
          <feather-icon icon="ChevronLeftIcon" />
        </b-button>
        {{ $t("CrearContactPerson") }}
      </h2>
    </div>
    <!-- form -->
    <b-card>
      <validation-observer ref="createUser">
        <b-form class="mt-2" @submit.prevent="handleSubmit" @keydown.enter="handleSubmit">
          <b-row>
           
            <!-- basic data -->
            <b-col sm="6">
              <b-form-group :label="$t('Nombre')" label-for="account-name">
                <b-form-input v-model="name" name="name" :placeholder="$t('Nombre')" />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group :label="$t('Apellidos')" label-for="account-surname">
                <b-form-input v-model="surname" :placeholder="$t('Apellidos')" name="surname" />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- contact -->
          <b-row>
            <b-col sm="6">
              <validation-provider #default="{ errors }" :name="$t('ClientPhone')" rules="max:9|min:9">
                <b-form-group :label="$t('ClientPhone')" label-for="account-phone">
                  <b-input-group>
                    <template #prepend>
                      <vue-country-code @onSelect="onSelect" :preferredCountries="['es']">
                      </vue-country-code>
                    </template>

                    <b-form-input type="number" v-model="phone" name="company" :placeholder="$t('Telefono')" />
                  </b-input-group>
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider #default="{ errors }" :name="$t('Email')" rules="required|email|emailUnique">
                <b-form-group :label="$t('Email')" label-for="account-e-mail">
                  <b-form-input v-model="email" type="email" name="email" :placeholder="$t('Email')" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <!-- company / client / subclient? -->
          <b-row>
            <b-col v-if="currentRole !== 'admin_cliente'" sm="6">
              <validation-provider #default="{ errors }" :name="$t('Compañía')" rules="required">
                <b-form-group :label="$t('Compañía')" label-for="account-company">
                  <v-select v-model="companies" label="name" :filterable="false" :searchable="false"
                    :options="selectCompanies" :placeholder="$t('Compañía')" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col v-if="currentRole !== 'admin_cliente'" sm="6">
              <validation-provider #default="{ errors }" :name="$t('Cliente')" :rules="roles &&
          (roles.name === 'admin_cliente' ||
            roles.name === 'operario_cliente' ||
            roles.name === 'prl_cliente')
          ? 'required'
          : ''
          ">
                <b-form-group :label="$t('Cliente')" label-for="account-clients">
                  <v-select v-model="clients" label="name" :filterable="false" :searchable="false"
                    :options="selectClients" multiple :disabled="disabledClients" :placeholder="$t('Cliente')" />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col cols="12 text-right">
              <b-button type="submit" variant="primary" class="mt-2 mr-1">
                {{ $t("Guardar") }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import ImageDropzone from "@/components/elements/ImageDropzone/ImageDropzone.vue";
import vSelect from "vue-select";
import VueCountryCode from "vue-country-code-select";

export default {
  components: {
    ImageDropzone,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    VueCountryCode,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      subclient: this.$route.params.subclient_id,
      companies: "",
      clients: [],
      roles: "",
      departments: [],
      files: [],
      profileFile: null,
      required,
      email,
      not_email: false,
      disableUsername: true,
      disableEmail: false,
      requiredEmail: "required|email|emailUnique",
      requiredUsername: "",
      userPrepare: false,
      disabledClients: true,
      surname: "",
      username: "",
      name: "",
      phone: "",
      dni: "",
      codePhone: "es",
      codePhoneSave: JSON.stringify({
        iso: "ES",
        code: 34,
      }),
      avatar: "",
      show1: false,
      password: "",
    };
  },
  computed: {
    ...mapGetters({
      currentClient: "clients/getCurrentClient",
      selectCompanies: "companies/getListCompanies",
      userSelectRoles: "users/getRoles",
      selectClients: "clients/getSelectClients",
      departmentsSelect: "departments/getSelectDepartments",
      currentRole: "auth/getRole",
      currentCompany: "companies/getCurrentCompany",

    }),
    selectRoles() {
      const arrayOutput = [];
      this.userSelectRoles.forEach((element) => {
        if (element.name !== "admin_cliente") {
          arrayOutput.push(element);
        } else if (!this.not_email) {
          arrayOutput.push(element);
        }
      });
      return arrayOutput;
    },
  },
  watch: {
    companies() {
      this.clients = [];

      if (this.companies) {
        this.searchClients({
          company: this.companies.id,
          client: this.currentClient,
        });
        this.disabledClients = false;
      } else {
        this.disabledClients = true;
      }
    },
    clients() {
      this.departments = [];

      const aux = this;
      if (this.clients.length > 0) {
        aux.selectDepartments({ clients: this.clients });
      } else {
        this.setSelectDepartments([]);
      }
    },
  },
  methods: {
    ...mapActions({
      create: "users/contact_person",
      searchCompanies: "companies/searchCompanies",
      searchClients: "clients/selectClients",
      selectDepartments: "departments/selectDepartments",
      getRoles: "users/getRoles",

    }),
    ...mapMutations({
      setSelectDepartments: "departments/setSelectDepartments",
    }),
    goBack() {
      this.$router.go(-1);
    },
    setClients() {
      if (this.currentRole == "admin_cliente") {
        this.clients = [this.currentClient];
        this.selectDepartments({ clients: this.clients });
      }
    },
    handleSubmit() {
      this.$refs.createUser.validate().then((success) => {
        if (success) {
          const formData = this.createFormData();
          if (formData) {
            this.create({ user: formData });
          }
        }
      });
    },
    createFormData() {
      // const data = this.$refs.images.getFormData();
      const data = new FormData();

      data.append("role", "client"); //forzamos que sea del rol client (contact_person)
      data.append("name", this.name);
      data.append("surname", this.surname);

      data.append("email", this.email);
      data.append("username", this.email);

      data.append("phone", this.phone);
      data.append("code_phone", this.codePhoneSave);

      if (this.currentRole == "admin_cliente") {
        data.append("companies[]", this.currentCompany.id);
        data.append("clients[]", this.currentClient.id);
      } else {
        if (this.companies) {
          data.append("companies[]", this.companies.id);
        }
        if (this.clients.length > 0) {
          this.clients.forEach((element) => {
            data.append("clients[]", element.id);
          });
        } else {
          data.append("clients", "");
        }
      }

      data.append("subclients[]", this.subclient);

      return data;
    },
    onSelect({ name, iso2, dialCode }) {
      this.codePhone = dialCode;
      this.codePhoneSave = JSON.stringify({
        iso: iso2,
        code: dialCode,
      });
    },
  },
  async created() {
    await this.searchCompanies();
    await this.setClients();
    await this.getRoles();
  },
  destroyed() {
    this.$store.commit("users/setUser", {});
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
